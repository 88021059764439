<template>
<div>
  比赛
</div>
</template>

<script>
export default {
  name: "Contests"
}
</script>

<style scoped>

</style>